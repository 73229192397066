import { Heading, Text, Button, VStack } from '@chakra-ui/react';
import NextLink from 'next/link';
import { Layout } from 'components';

const NotFoundPage = () => {
  return (
    <Layout title='404' description='Not Found'>
      <VStack spacing={2} justify='center' py={10} px={6} h='100vh'>
        <Heading
          display='inline-block'
          as='h2'
          size='2xl'
          bgGradient='linear(to-r, brand.400, brand.600)'
          backgroundClip='text'
        >
          404
        </Heading>
        <Text fontSize='18px' mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={'gray.500'} mb={6}>
          The page you&apos;re looking for does not seem to exist
        </Text>

        <NextLink href='/' passHref>
          <Button
            colorScheme='brand'
            bgGradient='linear(to-r, brand.400, brand.500, brand.600)'
            color='white'
            variant='solid'
          >
            Go to Home
          </Button>
        </NextLink>
      </VStack>
    </Layout>
  );
};

export default NotFoundPage;
